var __jsx = React.createElement;
import React from 'react';
import SocialIcon from '../../atoms/SocialIcon';
import componentAnalytics from '../../../constants/analyticsConstants';
import styled from 'styled-components';
import styles from './SocialIcons.style';
var SocialIconBlock = styled.section.withConfig({
  componentId: "sc-1lai8ft-0"
})(["", ";"], styles);
var SocialIcons = function SocialIcons(_ref) {
  var locationName = _ref.locationName,
    socialIconsData = _ref.socialIconsData,
    dataAnalyticsSocialLinks = _ref.dataAnalyticsSocialLinks,
    styleType = _ref.styleType;
  var facebookIcon = socialIconsData.facebookIcon,
    facebookUrl = socialIconsData.facebookUrl,
    twitterUrl = socialIconsData.twitterUrl,
    twitterIcon = socialIconsData.twitterIcon,
    instagramUrl = socialIconsData.instagramUrl,
    instagramIcon = socialIconsData.instagramIcon,
    linkedInUrl = socialIconsData.linkedInUrl,
    linkedInIcon = socialIconsData.linkedInIcon,
    youTubeUrl = socialIconsData.youTubeUrl,
    youTubeIcon = socialIconsData.youTubeIcon,
    shareUrl = socialIconsData.shareUrl,
    shareIcon = socialIconsData.shareIcon;
  var dataAnalyticsSocial = dataAnalyticsSocialLinks;
  return __jsx(SocialIconBlock, {
    className: "nva-social-icons social-icons"
  }, shareUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    socialUrl: shareUrl,
    icon: shareIcon,
    className: "nva-social-icons__share-icon"
  }), facebookUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_FACEBOOK,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_FACEBOOK,
    socialUrl: facebookUrl,
    icon: facebookIcon,
    className: "nva-social-icons__facebook-icon"
  }), twitterUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_TWITTER,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_TWITTER,
    socialUrl: twitterUrl,
    icon: twitterIcon,
    className: "nva-social-icons__twitter-icon"
  }), instagramUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_INSTAGRAM,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_INSTAGRAM,
    socialUrl: instagramUrl,
    icon: instagramIcon,
    className: "nva-social-icons__instagram-icon"
  }), linkedInUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_LINKEDIN,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_LINKEDIN,
    socialUrl: linkedInUrl,
    icon: linkedInIcon,
    className: "nva-social-icons__linkedin-icon"
  }), youTubeUrl && __jsx(SocialIcon, {
    dataAnalyticsType: "icon-social-feed",
    locationName: locationName,
    dataAnalyticsValue: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_YOUTUBE,
    dataAnalyticsVariation: dataAnalyticsSocial && dataAnalyticsSocial.variation ? dataAnalyticsSocial.variation : '',
    socialIconType: componentAnalytics.sociallinks.analyticsValue.SOCIAL_LINK_YOUTUBE,
    socialUrl: youTubeUrl,
    icon: youTubeIcon,
    className: "nva-social-icons__youtube-icon"
  }));
};
SocialIcons.defaultProps = {
  socialIconsData: '',
  dataAnalyticsSocialLinks: '',
  styleType: ''
};
export default SocialIcons;